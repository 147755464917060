import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { ModalFormWrapper } from '@/components/form/modal';
import { useGraphQL } from '@/data';
import { ClientsRead } from '@/data/management/client.graphql';
import { CompanyRead_50b7Query, CompanyRead_50b7QueryVariables } from '@/generated/graphql';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import { useModal } from '@/providers/modal';
import type { Client, QueryClientsSearchReadArgs } from '@/types/schema';
import { gql } from '@apollo/client';
import { Chip, Divider, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ClientForm from '../dashboard/management/clients/form';

export default function ClientSelect( props: Omit<FormGraphQLAutoCompleteProps<Client, QueryClientsSearchReadArgs>, 'query' | 'queryKey'> ) {
	const creatable = usePermissions( permissions.clients.write );
	const { showModal } = useModal();
	const { t } = useTranslation();
	const [ , { value = [] }, { setValue } ] = useField( props.name );
	const formik = useFormikContext<any>();
	
	const { data } = useGraphQL<CompanyRead_50b7QueryVariables, CompanyRead_50b7Query>( {
		query: gql`
			query CompanyRead_50b7 {
				companyRead {
					locations {
						name
						gateway {
							externalId
						}
					}
				}
			}
		`,
		queryKey: [ 'company' ],
	}, { keepPreviousData: true } );
	
	return (
		<FormGraphQLAutocomplete<Client>
			queryKey={[ 'client' ]}
			query={ClientsRead}
			getOptionLabel={( { contact, name, email, phone, gateway }: Client ) => [
				name,
				contact,
				email ? ` - ${email}` : '',
				phone,
				gateway ? ` - ${gateway.externalId}` : '',
			].join( ' ' ).trim()}
			renderOption={( props, option ) => {
				const location = data?.companyRead?.locations?.find( ( location ) => location.gateway?.externalId === option.gateway?.externalId );
				return (
					<Fragment key={option.id}>
						<MenuItem {...props}>
							<Stack direction='row' spacing={1} justifyContent='space-between' sx={{ width: '100%' }}>
								<Stack alignSelf='start'>
									{option.contact !== '-' && (
										<Typography fontWeight={500}>
											{option.contact}
										</Typography>
									)}
									{option.name && option.name !== option.contact && (
										<Typography>
											{option.name}
										</Typography>
									)}
									{option.email && (
										<Typography color='text.secondary'>
											{option.email}
										</Typography>
									)}
									{option.phone && (
										<Typography color='text.secondary'>
											{option.phone}
										</Typography>
									)}
									<Stack direction='row'>
										{option?.gateway && (
											<Typography color='text.secondary'>
												{option.externalValue ? t( 'common:quickbooks' ) : 'Clover'}
											</Typography>
										)}
										{option.gateway?.externalId && location && data?.companyRead?.locations.length > 1 && (
											<Typography color='text.secondary'>
												: {location.name}
											</Typography>
										)}
									</Stack>
								</Stack>
								{!isEmpty( option.houseAccounts ) && (
									<Tooltip title='House Account Holder'>
										<Chip
											variant='alpha'
											color='primary'
											label='Acct.'
										/>
									</Tooltip>
								)}
							</Stack>
						</MenuItem>
						<Divider sx={{ my: 1 }}/>
					</Fragment>
				);
			}}
			onAdd={creatable ? () => showModal( ClientForm, { fullPageModal: true, maxWidth: 'sm' }, {
				Wrapper : ModalFormWrapper,
				onSubmit: ( client ) => {
					if ( !isEmpty( client?.addresses ) ) formik.setFieldValue( 'clientAddress', client.addresses[ client.addresses.length - 1 ] );
					setValue( props.multiple ? [ ...value, client ] : client );
				},
			} ) : undefined}
			{...props}
			textFieldProps={{ label: props.label || t( 'common:client' ), ...props.textFieldProps }}
		/>
	);
}
