import Form from '@/components/form';
import GraphqlTable from '@/components/graphqlTable';
import { LineItemsRead } from '@/data/management/lineItem.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import safeDateFns from '@/helpers/safeFormat';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { LineItem, QueryLineItemsReadArgs } from '@/types/schema';
import { PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { endOfDay, format, startOfMonth } from 'date-fns';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import CommerceStatusChip from '../../commerce/chips/commerceStatusChip';
import {
	CalculateClients,
	CalculateModifiedTotal,
	CalculateNumber,
	CalculateTotal,
	CalculateUnique,
} from '../../commerce/components/tableHelpers';
import ItemReportFilters from './itemReportFilters';

export const basePurchaseColumns: Column<LineItem>[] = [ {
	accessor      : 'id',
	Header        : 'common:id',
	disableSortBy : true,
	disableFilters: true,
	width         : 10,
	props         : { style: { minWidth: 50 } },
}, {
	accessor: 'name' as any,
	Header  : 'management:name',
	props   : { style: { minWidth: 220 } },
	width   : 20,
	Footer  : ( { rows } ) => CalculateNumber( rows, 'Name' ),
}, {
	accessor   : 'purchase.number' as any,
	Header     : '#',
	sortType   : 'select',
	filterByKey: 'purchase.number',
	width      : 15,
	props      : { style: { minWidth: 140 } },
	Footer     : ( { rows } ) => CalculateNumber( rows, 'Commerce' ),
}, {
	accessor   : 'purchase.menu.vendorName' as any,
	Header     : 'management:vendor',
	sortType   : 'select',
	filterByKey: 'purchase.menu.vendorName',
	props      : { style: { minWidth: 135 } },
	width      : 12,
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor      : 'purchase.createdAt' as any,
	Header        : 'management:created-date',
	disableFilters: true,
	filterByKey   : 'purchase.serviceDate',
	width         : 18,
	props         : { style: { minWidth: 120 } },
	Cell          : ( { value } ) => value ? format( value, 'PPp' ) : ( '' as any ),
}, {
	accessor: 'purchase.serviceDate' as any,
	Header  : 'common:service-date',
	sortType: 'datetime',
	width   : 18,
	props   : { style: { minWidth: 120 } },
	Cell    : ( { value } ) => value ? format( value, 'PPp' ) : ( '' as any ),
}, {
	accessor      : 'purchase.companyLocation.address.line1' as any,
	Header        : 'management:location',
	width         : 10,
	disableFilters: true,
	props         : { style: { minWidth: 80 } },
}, {
	accessor     : 'purchase.status' as any,
	Header       : 'common:status',
	filterByKey  : 'status',
	filterOptions: [
		'PAID',
		'SENT',
		'DRAFT',
		'PARTIALLY_PAID',
		'CANCELLED',
		'REFUNDED',
		'CONFIRMED',
		'STANDING',
		'COMPLETED',
		'VIEWED',
	],
	width : 10,
	props : { style: { minWidth: 110 } },
	Cell  : ( data ) => <CommerceStatusChip cellData={data}/>,
	Footer: ( { rows } ) => CalculateUnique( rows, 'status' ),
}, {
	accessor: 'quantity',
	Header  : 'management:ordered',
	sortType: 'number',
	width   : 7,
	props   : { style: { minWidth: 45 } },
	Footer  : ( { rows } ) => CalculateTotal( rows, 'quantity' ),
}, {
	accessor: 'receivedQuantity',
	Header  : 'management:received',
	sortType: 'number',
	width   : 7,
	props   : { style: { minWidth: 45 } },
	Footer  : ( { rows } ) => CalculateTotal( rows, 'quantity' ),
}, {
	accessor      : ( row ) => ( row.quantity || 0 ) - ( row.receivedQuantity || 0 ),
	Header        : 'Back Order',
	sortType      : 'number',
	width         : 7,
	disableFilters: true,
	disableSortBy : true,
	props         : { style: { minWidth: 45 } },
	Cell          : ( { value } ) => value as any,
}, {
	accessor: 'uom.quantity' as any,
	Header  : 'management:stock',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
}, {
	accessor: 'cost',
	Header  : 'management:cost',
	sortType: 'number',
	width   : 7,
	props   : { style: { minWidth: 45 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'cost', true ),
}, {
	accessor: 'unit',
	Header  : 'management:uom',
	width   : 7,
	props   : { style: { minWidth: 45 } },
	Footer  : ( { rows } ) => CalculateUnique( rows, 'unit' ),
}, {
	accessor      : ( row ) => row.quantity * row.price || 0,
	Header        : 'common:total',
	disableFilters: true,
	disableSortBy : true,
	width         : 7,
	props         : { style: { minWidth: 45, justifyContent: 'end' } },
	Cell          : ( { value } ) => currencyFormat( value ) as any,
	Footer        : ( { rows } ) => CalculateModifiedTotal( rows ),
}, {
	accessor      : ( row ) => row,
	Header        : 'management:payment',
	disableFilters: true,
	disableSortBy : true,
	width         : 7,
	props         : { style: { minWidth: 45, justifyContent: 'end' } },
	Cell          : ( { value } ) => {
		const lineItemTotal = value.quantity * value.price;
		return currencyFormat( value.purchase?.paidTotal > lineItemTotal
			? lineItemTotal
			: value.order?.paidTotal ) as any;
	},
} ];

export default function PurchaseReport() {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const viewAllPurchases = checkViewAllPermission( 'purchases' );
	
	const [ filterByVendor, setFilterByVendor ] = useState( null );
	const [ companyLocation, setCompanyLocation ] = useState( null );
	const [ dates, setDates ] = useState( [ startOfMonth( new Date() ), endOfDay( new Date() ) ] );
	const [ placedBy, setPlacedBy ] = useState( null );
	const [ isGrouped, setIsGrouped ] = useState( false );
	
	const columns: Column<LineItem>[] = useMemo( () => {
		const columnsToRemoveWhenGrouped = [ '#', 'management:client', 'management:created-date', 'management:type' ];
		return isGrouped
			? basePurchaseColumns.filter( ( column ) => !columnsToRemoveWhenGrouped.includes( column.Header as string ) )
			: basePurchaseColumns;
	}, [ isGrouped ] );
	
	return (
		<Fragment>
			<Form
				initialValues={{
					menu           : null,
					companyLocation: null,
					newDates       : [ startOfMonth( new Date() ), endOfDay( new Date() ) ],
					placedBy       : null,
				}}
				onSubmit={( values ) => {
					setFilterByVendor( values.menu?.id );
					setCompanyLocation( values.companyLocation?.id );
					setDates( values.newDates );
					setPlacedBy( values.placedBy );
				}}>
				<ItemReportFilters type='purchase'/>
			</Form>
			<GraphqlTable<LineItem, QueryLineItemsReadArgs>
				disableUrlSync
				searchable
				showFooter
				queryKey='lineItems'
				query={LineItemsRead}
				columns={columns}
				hiddenTableColumns='itemReportsHiddenColumns'
				variables={{
					options: {
						filter: {
							store   : null,
							purchase: {
								menu           : filterByVendor || undefined,
								companyLocation: companyLocation || undefined,
								deletedAt      : null,
								status         : { $nin: [ 'CANCELLED', 'DECLINED' ] },
								staff          : viewAllPurchases
									? placedBy?.id || undefined
									: staff?.id,
								createdAt: dates.filter( ( date ) => safeDateFns.isValid( date ) )?.length > 1
									? {
										$gte: dates[ 0 ],
										$lte: dates[ 1 ],
									}
									: undefined,
							},
						},
					},
				}}
				expandedComponent={( row ) => (
					<Paper sx={{ p: 1, width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
						<Typography color='text.primary'>
							{row.description
								? t( 'management:description-value', { description: row.description } )
								: t( 'common:no-description' )}
						</Typography>
					</Paper>
				)}
				initialState={{
					hiddenColumns: staff.metadata?.itemReportsHiddenColumns
						? staff.metadata?.itemReportsHiddenColumns
						: [ 'id', 'purchase.companyLocation.address.line1', 'purchase.serviceDate' ],
				}}
				isGroupedBy={isGrouped}
				headerExtraActions={{
					items: [ {
						name       : t( 'common:group-items' ) as string,
						icon       : <PlaylistAddIcon/>,
						buttonProps: {
							variant: 'outlined',
							sx     : { animation: isGrouped ? 'ripple2 1s linear infinite' : undefined },
						},
						onClick: () => setIsGrouped( ( prev ) => !prev ),
					} ],
				}}
			/>
		</Fragment>
	);
}
