import Form from '@/components/form';
import GraphqlTable from '@/components/graphqlTable';
import UserPopover from '@/components/userPopover';
import { useGraphQL } from '@/data';
import { LineItemsSalesReport } from '@/data/management/lineItem.graphql';
import { Client, Location, Menu, Staff } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import LineItemTagChip from '@/pages/dashboard/commerce/chips/lineItemTagChip';
import ItemReportQuickFilterActions from '@/pages/dashboard/management/reports/actions/itemReportQuickFilterActions';
import { ItemsReportRead } from '@/pages/dashboard/management/reports/utils';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { LineItem, QueryLineItemsReadArgs, QueryLineItemsSalesReportArgs } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material';
import { Avatar, Box, Chip, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { endOfDay, format, startOfMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isEmpty, round } from 'lodash-es';
import dynamic from 'next/dynamic';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import {
	CalculateClients,
	CalculateModifiedTotal,
	CalculateNumber,
	CalculateTotal,
	CalculateUnique,
} from '../../commerce/components/tableHelpers';
import ItemReportFilters from './itemReportFilters';
import MobileRowPanel from './mobileRow';

const StatCard = dynamic( () => import( '@/pages/dashboard/main/chart/statCard' ), { ssr: false } );

export const itemSalesHeaders: ( t ) => {
	header: string,
	key: string,
	description: string,
	type?: string
}[] = ( t ) => [
	{
		header     : t( 'common:gross-sales' ),
		key        : 'grossSales',
		description: t( 'common:gross-sales-desc' ),
	},
	{
		header     : t( 'common:net-sales' ),
		key        : 'netSales',
		description: t( 'common:net-sales-desc' ),
	},
	{
		header     : t( 'commerce:cogs' ),
		key        : 'cogs',
		description: t( 'common:cogs-desc' ),
	},
	{
		header     : t( 'commerce:gross-profit' ),
		key        : 'grossProfit',
		description: t( 'common:gross-profit-desc' ),
	},
	{
		header     : t( 'commerce:gross-profit-margin' ),
		key        : 'grossProfitMargin',
		description: t( 'common:gross-profit-margin-desc' ),
		type       : 'Percent',
	} ];

export const baseOrderColumns: () => Array<Column<LineItem>> = () => [ {
	accessor      : 'id',
	Header        : 'common:id',
	disableSortBy : true,
	disableFilters: true,
	width         : 10,
	props         : { style: { minWidth: 50 } },
}, {
	accessor: 'name' as any,
	Header  : 'management:name',
	props   : { style: { minWidth: 280 } },
	width   : 20,
	Footer  : ( { rows } ) => CalculateNumber( rows, 'Name' ),
}, {
	accessor   : 'item.glcode' as any,
	Header     : 'GLCode',
	sortType   : 'select',
	filterByKey: 'item.glcode',
	width      : 15,
	Cell       : ( { value } ) => value || '',
	props      : { style: { minWidth: 40 } },
}, {
	accessor   : 'order' as any,
	Header     : '#',
	filterByKey: [ 'order.number', 'order.metadata.customNumber' ],
	width      : 15,
	props      : { style: { minWidth: 140 } },
	Cell       : ( { value } ) =>
		value?.metadata?.customNumber || value?.number || value?.externalId,
	Footer: ( { rows } ) => CalculateNumber( rows, 'Commerce' ),
}, {
	accessor   : ( row ) => row.order?.client?.name || row.order?.client?.contact,
	Header     : 'management:client',
	filterByKey: [ 'order.client.name', 'order.client.contact' ],
	props      : { style: { minWidth: 135 } },
	width      : 12,
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor   : 'order.staff' as any,
	Header     : 'common:placed-by',
	sortType   : 'select',
	filterByKey: 'order.staff.user.firstName',
	width      : 10,
	props      : { style: { minWidth: 110 } },
	Cell       : ( { value } ) => (
		<UserPopover user={value?.user && { ...value?.user, createdAt: value?.createdAt }}>
			<Chip
				label={value?.user?.firstName}
				size='small'
				avatar={(
					<Avatar
						alt={value?.user?.firstName}
						src={value?.user?.image}
						sx={{
							border     : 1.5,
							borderColor: 'colors.opposite',
						}}
					/>
				)}
			/>
		</UserPopover>
	),
}, {
	accessor   : 'category.name' as any,
	Header     : 'management:category',
	sortType   : 'select',
	filterByKey: 'category.name',
	width      : 15,
	props      : { style: { minWidth: 100 } },
}, {
	accessor     : 'status',
	Header       : 'common:tag',
	disableSortBy: true,
	props        : { style: { minWidth: 135 } },
	width        : 12,
	Cell         : ( { row } ) => (
		<LineItemTagChip
			removePlaceholder
			status={row.original.status as string}
			company={row.original.order?.company || row.original.purchase?.company}
		/>
	),
}, {
	accessor      : 'order.createdAt' as any,
	Header        : 'management:created-date',
	disableFilters: true,
	filterByKey   : 'order.serviceDate',
	width         : 18,
	props         : { style: { minWidth: 120 } },
	Cell          : ( { value } ) => value ? format( value, 'PPp' ) : ( '' as any ),
}, {
	accessor: 'order.serviceDate' as any,
	Header  : 'common:service-date',
	sortType: 'datetime',
	width   : 18,
	props   : { style: { minWidth: 120 } },
	Cell    : ( { value } ) => value ? format( value, 'PPp' ) : ( '' as any ),
}, {
	accessor      : 'order.companyLocation.address.line1' as any,
	Header        : 'management:location',
	width         : 10,
	disableFilters: true,
	props         : { style: { minWidth: 80 } },
}, {
	accessor      : 'order.type' as any,
	Header        : 'management:type',
	width         : 8,
	disableFilters: true,
	disableSortBy : true,
	props         : { style: { minWidth: 80 } },
	Cell          : ( { value } ) => (
		<Chip
			variant='alpha'
			color={value === 'ORDER' ? 'success' : 'warning'}
			label={value}
		/>
	),
}, {
	accessor: 'quantity',
	Header  : 'management:quantity',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 50 } },
	Footer  : ( { rows } ) => CalculateTotal( rows, 'quantity' ),
}, {
	accessor: 'price',
	Header  : 'management:price',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'price', true ),
}, {
	accessor: 'cost',
	Header  : 'management:cost',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'cost', true ),
}, {
	accessor: 'uom.quantity' as any,
	Header  : 'management:stock',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
}, {
	accessor: 'unit',
	Header  : 'management:uom',
	width   : 10,
	props   : { style: { minWidth: 80 } },
	Footer  : ( { rows } ) => CalculateUnique( rows, 'unit' ),
}, {
	accessor      : ( row ) => row.quantity * row.price || 0,
	Header        : 'common:total',
	disableFilters: true,
	disableSortBy : true,
	width         : 10,
	props         : { style: { minWidth: 80 } },
	Cell          : ( { value } ) => currencyFormat( value ) as any,
	Footer        : ( { rows } ) => CalculateModifiedTotal( rows ),
}, {
	accessor      : ( row ) => row,
	Header        : 'management:payment',
	disableFilters: true,
	disableSortBy : true,
	width         : 10,
	props         : { style: { minWidth: 80, justifyContent: 'end' } },
	Cell          : ( { value } ) => {
		const lineItemTotal = value.quantity * value.price;
		return currencyFormat( value.order?.paidTotal > lineItemTotal
			? lineItemTotal
			: value.order?.paidTotal ) as any;
	},
} ];

export type CustomItemReportFilters = {
	statusFilter?: any,
	typeFilter: any,
	staffId?: string,
	datesFilter?: Array<Date>
};

export default function ItemReport( {
	customFilters,
	hideItemFilters,
	hiddenColumnsText,
}: {
	customFilters?: CustomItemReportFilters,
	hideItemFilters?: boolean,
	hiddenColumnsText?: string
} ) {
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const timezone = getBrowserTimezone();
	const [ status, setStatus ] = useState( '' );
	const [ filterByClient, setFilterByClient ] = useState<string | null>( null );
	const [ filterByVendor, setFilterByVendor ] = useState<string | null>( null );
	const [ companyLocation, setCompanyLocation ] = useState<string | null>( null );
	const [ dates, setDates ] = useState( customFilters?.datesFilter || [ startOfMonth( new Date() ),
		endOfDay( new Date() ) ] );
	const [ type, setType ] = useState( customFilters?.typeFilter || null );
	const [ placedBy, setPlacedBy ] = useState<Staff | null>( null );
	const [ isGrouped, setIsGrouped ] = useState( false );
	const validDates = dates.filter( Boolean );
	const viewAllCommerces = checkViewAllPermission( 'commerces' );
	
	const { data } = useGraphQL<QueryLineItemsSalesReportArgs>( {
		queryKey : [ 'lineItemsSalesReport' ],
		query    : LineItemsSalesReport,
		variables: {
			timezone,
			dates: !isEmpty( validDates ) ? validDates
				.map( ( d ) => `${d.toISOString().slice( 0, 10 )} 00:00:00` )
				.map( ( date ) => zonedTimeToUtc( date, timezone ).toISOString() ) : [],
			options: {
				filter: {
					order: {
						client         : filterByClient || undefined,
						companyLocation: companyLocation || undefined,
						type           : type || undefined,
						staff          : viewAllCommerces
							? placedBy?.id || undefined
							: staff?.id,
					},
				},
			},
		},
	}, {
		keepPreviousData: true,
		enabled         : !!validDates?.length && !hideItemFilters,
	} );
	
	const columns: Column<LineItem>[] = useMemo( () => {
		const columnsToRemoveWhenGrouped = [ '#', 'management:created-date', 'management:type' ];
		const allColumns = baseOrderColumns();
		return isGrouped
			? allColumns.filter( ( column ) => !columnsToRemoveWhenGrouped.includes( column.Header as string ) )
			: allColumns;
	}, [ isGrouped ] );
	
	const itemSalesData = data?.lineItemsSalesReport;
	
	return (
		<Fragment>
			<Form<{
				client: Client | null,
				companyLocation: Location | null,
				menu: Menu | null,
				type: string | null,
				placedBy: Staff | null,
				newDates: [ Date, Date ]
			}>
				initialValues={{
					client         : null,
					companyLocation: null,
					newDates       : [ startOfMonth( new Date() ), endOfDay( new Date() ) ],
					type           : null,
					placedBy       : null,
					menu           : null,
				}}
				onSubmit={( values ) => {
					setFilterByVendor( values.menu?.id ?? null );
					setFilterByClient( values.client?.id ?? null );
					setCompanyLocation( values.companyLocation?.id ?? null );
					setDates( values.newDates );
					setType( values.type );
					setPlacedBy( values.placedBy );
				}}>
				{!hideItemFilters && (
					<Fragment>
						<ItemReportFilters type='item'/>
						<Grid container>
							{!isEmpty( validDates ) && itemSalesHeaders( t ).map( ( item ) => (
								<Grid key={item.key} item xs={12} sm={2} sx={{ p: 2 }}>
									<Tooltip title={item.description}>
										<Box>
											<StatCard
												removeGraph
												title={item.header}
												value={item?.type === 'Percent'
													? `${round( itemSalesData?.[ item.key ] || 0, 2 )}%`
													: currencyFormat( itemSalesData?.[ item.key ] )}
												animationVariant='slideLeft'
											/>
										</Box>
									</Tooltip>
								</Grid>
							) )}
						</Grid>
					</Fragment>
				)}
			</Form>
			<GraphqlTable<LineItem, QueryLineItemsReadArgs>
				disableUrlSync
				searchable
				showFooter
				queryKey='lineItems'
				query={ItemsReportRead}
				columns={columns}
				hiddenTableColumns={hiddenColumnsText || 'itemReportsHiddenColumns'}
				variables={{
					options: {
						filter: {
							status: status || undefined,
							uom   : filterByVendor ? { menus: { $in: [ filterByVendor ] } } : undefined,
							order : {
								client         : filterByClient || undefined,
								companyLocation: companyLocation || undefined,
								type           : type || {
									$nin: [ 'ESTIMATE',
										'BID',
										'QUOTE',
										'PROPOSAL',
										'WORK_ORDER',
										'ACCOUNT',
										'STATEMENT' ],
								},
								templateName: null,
								deletedAt   : null,
								status      : customFilters?.statusFilter || { $nin: [ 'CANCELLED', 'STANDING' ] },
								staff       : customFilters?.staffId || viewAllCommerces
									? customFilters?.staffId || placedBy?.id || undefined
									: staff?.id,
								createdAt: !isEmpty( validDates )
									? {
										$gte: validDates[ 0 ],
										$lte: validDates[ 1 ],
										
									} : undefined,
							},
						},
					},
				}}
				expandedComponent={( row ) => (
					<Paper sx={{ p: 1, width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
						<Typography color='text.primary'>
							{row.description
								? t( 'management:description-value', { description: row.description } )
								: t( 'common:no-description' )}
						</Typography>
					</Paper>
				)}
				initialState={{
					hiddenColumns:
						staff?.metadata?.[ hiddenColumnsText ?? '' ] || staff?.metadata?.ordersHiddenColumns
						|| [ 'id', 'order.companyLocation.address.line1', 'order.serviceDate' ],
				}}
				mobileRenderRow={( lineItem ) => <MobileRowPanel lineItem={lineItem}/>}
				isGroupedBy={isGrouped}
				useQuickFilters={() => ItemReportQuickFilterActions( setStatus )}
				headerExtraActions={{
					items: [ {
						name       : t( 'common:group-items' ) as string,
						icon       : <PlaylistAddIcon/>,
						buttonProps: {
							variant: 'outlined',
							sx     : { animation: isGrouped ? 'ripple2 1s linear infinite' : undefined },
						},
						onClick: () => setIsGrouped( ( prev ) => !prev ),
					} ],
				}}
			/>
		</Fragment>
	);
}
