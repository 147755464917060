import { ExportPaymentsCSV } from '@/data/commerce/payment.graphql';
import {
	ExportLineItemGroupedCSVString,
	ExportLineItemsCSV,
	ExportLineItemsTaxReportCSV,
} from '@/data/management/lineItem.graphql';
import { ExportHouseAccountSalesReportCSV } from '@/data/management/report.graphql';
import csvExport from '@/helpers/csvExport';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import useUserInfo from '@/providers/auth/useUserInfo';
import { getBrowserTimezone } from '@/utils/timezone';
import { urlSearchParams } from '@/utils/urlSearchParams';
import { endOfDay, startOfMonth } from 'date-fns';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';

export default function useExportCSV( type: any, linkRef: React.MutableRefObject<any>, isGrouped?: boolean ) {
	const formik = useFormikContext<any>();
	const { staff } = useUserInfo();
	const router = useRouter();
	const fromDate = startOfMonth( new Date() ).toISOString();
	const toDate = endOfDay( new Date() ).toISOString();
	const viewAllPurchases = checkViewAllPermission( 'purchases' );
	const viewAllCommerces = checkViewAllPermission( 'commerces' );
	const viewAllPayments = checkViewAllPermission( 'payments' );
	
	return async ( task ) => {
		const tableState = router.query.s
			? JSON.parse( atob( decodeURIComponent( router.query.s as string ) ) )
			: {};
		
		const filterState = { filters: [] as any[] };
		
		const addPlacedByFilters = ( viewAllEntries, formikValues, type ) => {
			if ( formikValues.placedBy && viewAllEntries ) {
				const filter = {
					id   : type === 'item' ? 'order' : 'staff',
					value: {
						[ type === 'purchase' || type === 'payment' ? '$eq' : 'staff' ]: formikValues.placedBy?.id,
						temp                                                           : { checked: true },
					},
				};
				filterState.filters.push( filter );
			}
		};
		
		const addClientFilters = ( formikValues, filterState, type ) => {
			if ( formikValues.client && type === 'item' || type === 'payment' ) {
				const filter = {
					id   : 'order',
					value: {
						client: formikValues.client?.id,
						temp  : { checked: true },
					},
				};
				filterState.filters.push( filter );
			}
			if ( formikValues.menu && type === 'purchase' ) {
				const filter = {
					id   : 'purchase',
					value: {
						menu: formikValues.menu?.id,
						temp: { checked: true },
					},
				};
				filterState.filters.push( filter );
			}
		};
		addPlacedByFilters(
			type === 'item' ? viewAllCommerces
				: type === 'purchase' ? viewAllPurchases
					: type === 'payment' ? viewAllPayments : undefined, formik.values, type );
		
		addClientFilters( formik.values, filterState, type );
		
		const scenarioFilters = {
			payment: () => {
				filterState.filters.push( {
					id   : 'status',
					value: {
						$nin: [ 'OPEN', 'REFUNDED' ],
						temp: { checked: true },
					},
				}, {
					id   : 'createdAt',
					value: formik.values.startDate && formik.values.endDate
						? {
							$gte: formik.values.startDate.toISOString(),
							$lte: formik.values.endDate.toISOString(),
							temp: { checked: true },
						} : undefined,
				}, {
					id   : 'order',
					value: {
						status         : { $nin: [ 'DRAFT', 'CANCELLED', 'REFUNDED' ] },
						companyLocation: formik.values.companyLocation?.id || undefined,
						type           : formik.values.type || { $ne: 'ESTIMATE' },
						deletedAt      : null,
						paidTotal      : { $ne: 0 },
						temp           : { checked: true },
					},
				} );
				
			},
			purchase: () => {
				filterState.filters.push(
					{
						id   : 'store',
						value: null,
					},
					{
						id   : 'purchase',
						value: {
							companyLocation: formik.values.companyLocation?.id || undefined,
							status         : { $nin: [ 'CANCELLED', 'DECLINED', 'DRAFT' ] },
							deletedAt      : null,
							staff          : viewAllPurchases
								? formik.values.placedBy?.id || undefined
								: staff?.id,
							
							temp: { checked: true },
						},
					},
					{
						id       : 'purchase',
						createdAt: !isEmpty( formik.values.newDates.filter( Boolean ) )
							? {
								$gte: formik.values.newDates[ 0 ].toISOString(),
								$lte: formik.values.newDates[ 1 ].toISOString(),
								temp: { checked: true },
							} : undefined,
					},
					{
						id   : 'createdAt',
						value: !isEmpty( formik.values.newDates.filter( Boolean ) )
							? {
								$gte: formik.values.newDates[ 0 ].toISOString(),
								$lte: formik.values.newDates[ 1 ].toISOString(),
								temp: { checked: true },
							} : undefined,
					},
				);
			},
			item: () => {
				filterState.filters.push( {
					id   : 'store',
					value: {
						$eq : null,
						temp: { checked: true },
					},
				}, {
					id   : 'order',
					value: {
						type: formik.values.type || {
							$nin: [ 'ESTIMATE',
							        'BID',
							        'QUOTE',
							        'PROPOSAL',
							        'WORK_ORDER',
							        'ACCOUNT',
							        'STATEMENT' ],
						},
						deletedAt: null,
						staff    : viewAllCommerces
							? formik.values.placedBy?.id || undefined
							: staff?.id,
						companyLocation: formik.values.companyLocation?.id || undefined,
						status         : { $nin: [ 'DRAFT', 'CANCELLED' ] },
						temp           : { checked: true },
					},
				}, !isEmpty( formik.values.newDates.filter( Boolean ) ) ? {
					id   : 'order',
					value: {
						createdAt: {
							$gte: formik.values.newDates?.[ 0 ]?.toISOString() || fromDate,
							$lte: formik.values.newDates?.[ 1 ]?.toISOString() || toDate,
						},
						temp: { checked: true },
					},
				} : null );
			},
			taxes: () => {
				filterState.filters.push(
					formik.values.client?.id ? {
						id   : 'order',
						value: {
							client: formik.values.client.id,
							temp  : { checked: true },
						},
					} : null, {
						id   : 'order',
						value: {
							type : formik.values.type,
							staff: viewAllCommerces
								? formik.values.placedBy?.id || undefined
								: staff?.id,
							temp: { checked: true },
						},
					}, !isEmpty( formik.values.newDates.filter( Boolean ) ) ? {
						id   : 'order',
						value: {
							createdAt: [ formik.values.newDates?.[ 0 ] || fromDate, formik.values.newDates?.[ 1 ] || toDate ],
							temp     : { checked: true },
						},
					} : null );
			},
			stock: () => {
				filterState.filters.push( {
					id   : 'store',
					value: {
						$eq : null,
						temp: { checked: true },
					},
				}, {
					id   : 'item',
					value: {
						$ne: null,
						...formik.values?.searchValue && {
							name: { $ilike: formik.values?.searchValue },
						},
						uoms: {
							quantity: formik.values?.searchQuantity === ''
								? { $ne: null }
								: { [ formik.values?.operator ]: formik.values?.searchQuantity },
						},
						temp: { checked: true },
					},
				}, {
					id   : 'order',
					value: {
						type     : formik.values.type || { $ne: 'ESTIMATE' },
						deletedAt: null,
						staff    : viewAllCommerces
							? formik.values.placedBy?.id || undefined
							: staff?.id,
						companyLocation: formik.values.companyLocation?.id || undefined,
						status         : { $nin: [ 'DRAFT', 'CANCELLED' ] },
						temp           : { checked: true },
					},
				} );
			},
			houseAccount: () => {
				filterState.filters.push( {
					value: {
						temp : { checked: true },
						dates: [
							formik.values.startDate?.toISOString() || fromDate,
							formik.values.endDate?.toISOString() || toDate,
						],
					},
				} );
			},
			default: () => {
				filterState.filters.push( {
					id   : 'order',
					value: {
						status   : { $nin: [ 'DRAFT', 'CANCELLED' ] },
						type     : formik.values.type || { $ne: 'ESTIMATE' },
						deletedAt: null,
						temp     : { checked: true },
					},
				}, !isEmpty( formik.values.newDates.filter( Boolean ) ) ? {
					id   : 'order',
					value: {
						createdAt: {
							$gte: formik.values.newDates?.[ 0 ]?.toISOString() || fromDate,
							$lte: formik.values.newDates?.[ 1 ]?.toISOString() || toDate,
						},
						temp: { checked: true },
					},
				} : null );
			},
		};
		
		if ( scenarioFilters[ type ] ) {
			scenarioFilters[ type ]();
		} else {
			scenarioFilters.default();
		}
		
		if ( !isEmpty( tableState?.filters ) ) {
			tableState.filters = tableState.filters.map( ( filter ) => {
				if ( filter.id === 'order.serviceDate' ) {
					return {
						id   : 'order',
						value: {
							$or: [ {
								serviceDate: {
									$gte: filter.value.$gte || fromDate,
									$lte: filter.value.$lte || toDate,
								},
							}, {
								updatedAt: {
									$gte: filter.value.$gte || fromDate,
									$lte: filter.value.$lte || toDate,
								},
							} ],
							
							temp: { checked: true, operatorValue: filter.value.temp.operatorValue },
						},
					};
				} else {
					return filter;
				}
			} );
		}
		
		const state = {
			filters: tableState?.filters
				? [ ...tableState.filters, ...filterState?.filters ]
				: [ ...filterState?.filters ],
		};
		if ( task === 'pdf' && type === 'payment' ) {
			const pdfFilters = Buffer.from( JSON.stringify( state ) ).toString( 'base64' );
			
			if ( pdfFilters ) {
				window.open( `/api/preview/pdf/${staff?.company.id}/payments?${urlSearchParams( {
					s       : pdfFilters,
					timezone: getBrowserTimezone(),
				} )}`, '_blank' );
			}
		} else {
			let csvString, csvFileName;
			if ( type === 'purchase' || type === 'item' ) {
				const { data, filename } = await csvExport( state, isGrouped
					? ExportLineItemGroupedCSVString
					: ExportLineItemsCSV, type, undefined, isGrouped );
				csvString = data?.lineItemCSVString || data?.lineItemGroupedCSVString;
				csvFileName = filename;
			} else if ( type === 'payment' ) {
				const { data, filename } = await csvExport( state, ExportPaymentsCSV, 'payments' );
				csvString = data?.paymentCSVString;
				csvFileName = filename;
			} else if ( type === 'houseAccount' ) {
				const { data, filename } = await csvExport( state, ExportHouseAccountSalesReportCSV, 'houseAccount' );
				csvString = data?.exportHouseAccountSalesReportCSV;
				csvFileName = filename;
			} else if ( type === 'taxes' ) {
				const { data, filename } = await csvExport( state, ExportLineItemsTaxReportCSV, 'taxReport' );
				csvString = data?.lineItemsTaxReportCSV;
				csvFileName = filename;
			}
			
			if ( csvString ) {
				const blob = new Blob( [ csvString ], { type: 'text/csv;charset=utf-8;' } );
				const url = URL.createObjectURL( blob );
				const fixedEncodedURI = url.replace( /#/g, '%23' );
				linkRef.current.setAttribute( 'href', fixedEncodedURI );
				linkRef.current.setAttribute( 'download', csvFileName );
				linkRef.current.click();
			}
		}
	};
}
